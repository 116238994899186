.App {
  text-align: center;
}

.App-logo {
  display: block;
  margin: auto;
  text-align: center;
  position: relative;
  top:100px;
  height:50px;
  pointer-events: none;
  padding:0;
}

.App-Introduction {
  display: block;
  margin: auto;
  text-align: center;
  top:auto;
  height:230px;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}*/

.App-backGroud-pc
{
  height: 100%;  
  width: 100%;  
  position: absolute;  
  top: 0px;  
  bottom: 0px;  
  background: #00FF00 url("./Images/red.jpg");
  background-size: cover;  
  z-index: 0;  
  overflow:hidden; 
  word-break:break-all;
}

.App-backGroud-phone
{
  height: 100%;  
  width: 100%;  
  position: absolute;  
  text-align: center;
  top: 0px;  
  bottom: 0px;  
  background: #00FF00 url("./Images/red.jpg");
  background-position-x: center;  
  background-position-y: center;
  background-repeat: no-repeat;
  background-size:cover;
}

.App-backGroud-gif
{
  text-align: center;
  display: block;
  margin: auto;
  height: 100%;  
  width: 100%;  
  position: absolute;  
  top: 0px;  
  bottom: 0px; 
  left: 0px;
  right: 0px; 
  background: #f5d5e6 url("./Images/opening.gif");
  background-repeat: no-repeat;
  background-position-x: center;  
  background-position-y: center;
  background-size: cover;
}


.App-header {
  background-color: #9bbedb;
  min-height: 1vh;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  font-size: calc(10px + 1vmin);
  color: rgb(243, 233, 233);
  background-image:  url("./Images/greenCar.png");
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.1);
}

.lisample{
  color: rgb(186, 237, 252);
  display: block;
  float: left;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  font-size: smaller;
}

.lisample-userAvatar{
display: block;
float: left;
position: relative;
left: 15%;
}

.m-form-text {
  text-align: left;
  height: 3em;
  width: 100%;
  padding: 0 10px;
  margin-top: 25x;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 0 1px rgb(212, 210, 210) inset;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.m-form-text-login {
  text-align: left;
  height: 3em;
  width: 280px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 0 1px rgb(141, 142, 246) inset;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}



.m-form-title{
  text-align:center;
  height: 2em;
  width: 100%;
  padding: 0 10px;
  margin-top: 25x;
  border-radius: 2px;
  border: none;
  box-shadow: 0 0 0 1px rgb(211, 219, 248) inset;
  fill: rgb(108, 190, 190);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: rgb(14, 14, 15);
  background-color: rgb(201, 221, 255);

}

.m-form-text:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgb(33, 150, 243) inset;
}

.m-form-text-login:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgb(33, 150, 243) inset;
}


.m-form-submit-button {
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: rgb(162, 145, 145);
  color: #fff;
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border: 2px solid transparent;
  align-items: center;
}

.m-form-submit-button:hover {
  background-color: #000;
}

.m-form-submit-button:focus {
  outline: 0;
  background-color: rgb(55, 16, 183);
  border: 2px solid rgb(16, 163, 50);
}

.m-form-submit-button-login {
  text-align: center;
  width: 250px;
  border: none;
  border-radius: 4px;
  background-color: rgb(162, 145, 145);
  color: #fff;
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border: 2px solid transparent;
  align-items: center;
}

.m-form-submit-button-login:hover {
  background-color: #000;
}

.m-form-submit-button-login:focus {
  outline: 0;
  background-color: rgb(55, 16, 183);
  border: 2px solid rgb(16, 163, 50);
}

.loginTitle{
  min-height: 1vh;
  display: flex;
  flex-direction:row;
  align-items: start;
  justify-content: start;
  font-size: calc(20px + 1vmin);
  color: rgb(13, 13, 13);
  text-align: center;
  align-items: center;

}

.errorMessgae{
  color: rgb(215, 10, 10);

}

.App-Welcome-pc{
  position: relative;
  color: rgb(233, 101, 49);
  font-size:calc(30px + 1vmin);
  font-weight: bolder;
  font-style: italic;
  padding: 100px;
  margin: 0;
  text-align:center;
}

.App-Welcome-Phone{
  position: relative;
  color: rgb(233, 101, 49);
  font-size:calc(15px);
  font-weight: bolder;
  font-style: italic;
  margin: 0;
  text-align:center;
}

.botton-text{
  text-decoration:none;
  text-align:center;
  color: #fbfbfb;
}

.App-Welcome-Member{
  color: rgb(233, 101, 49);
  text-align: center;
  display: block;
  margin: auto;
  height: 100%;  
  width: 100%;  
  position: absolute;  
  top: 0px;  
  bottom: 0px; 
  left: 0px;
  right: 0px; 
  background: #f5d5e6 url("./Images/supercar2.jpg");
  background-repeat: no-repeat;
  background-position-x: center;  
  background-position-y: center;
  background-size: cover;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
